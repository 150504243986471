import React,{useState,useEffect} from "react"
import HeardTop from "../../components/heard-top"
import Layout from "../../components/layout/index"
import PageContainer from "../../components/page-container"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';
import {Table,Container,Row,Col,Image,Alert,Button} from "react-bootstrap"
import {authToLogin} from "../../utils/functions"
import img from "../../images/blog.jpg"
import GET_ORDER_DETAIL from "../../queries/get-order-detail"
import{graphql} from "gatsby"
import { useQuery } from "@apollo/client";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import moment from "moment"
const OrderDetail = ({}) => {
  const {t} = useTranslation();
  useEffect(() => {
    authToLogin()
  }, []);
  let getOrderID
  if (typeof window !== 'undefined') {
    getOrderID = window.location.search.split("?")[1] ? window.location.search.split("?")[1] : ""
  }
  const [orderId, setOrderId] = useState({id: getOrderID});
  const [cartProduct, setCartProduct] = useState([]);
  const [datePaid, setDatePaid] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [totalTax, setTotalTax] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [paymentMethodTitle,setPaymentMethodTitle] = useState(null);


  // //get order data
  const { data, refetch } = useQuery(GET_ORDER_DETAIL, {
    variables: orderId,
    onCompleted: () => {
      setBillingAddress(data.order.billing)
      setSubTotal(data.order.subtotal)
      setTotalPrice(data.order.total)
      setTotalTax(data.order.totalTax)
      setDatePaid(data.order.datePaid)
      setCartProduct(data.order.lineItems.edges)
      setOrderNumber(data.order.orderNumber)
      setOrderStatus(data.order.status)
      setPaymentMethodTitle(data.order.paymentMethodTitle)
    },
    onError: (error) => {
      console.log(error)
    },
    
  });
  return(
    <Layout>
      <Helmet>
        <title>{t('myKitchenInspector')}｜ {t('OrderDetail')}</title>
      </Helmet>
    <HeardTop title={t('OrderDetail')}/>
    <PageContainer>
      <>
      <div>
          <div className="topBreadscrum">
        <div className="topBreadscrumLeft"><Link to="/orderlist" className="top-nav">{t('orderlist')}</Link> &gt; {t('OrderDetail')}</div>
        {orderStatus === "REFUNDED" ? "" 
                   : orderStatus === "CANCELLED" ?  ""
                   : orderStatus === "COMPLETED" ?  ""
                   : orderStatus === "FAILED" ?  <div><Button className="addButton payButton">{t('Pay')}</Button><Button className="addButton">{t('Cancel')}</Button></div>
                   : orderStatus === "ON_HOLD" ?  ""
                   : orderStatus === "PENDING" ?   ""
                   : orderStatus === "PROCESSING" ?  ""
                   :""
                  }
      </div>
      {orderStatus==="CANCELLED" ? <Alert variant="warning" className="alert-custom">
                                      <i className="iconfont icon-warning_fill"></i> {t('orderCancelledDes')}  {t('please')} <Link to="/contact">{t('ContactUs')}</Link>.
                                    </Alert>
                                 : orderStatus==="COMPLETED" ?
                                 <Alert variant="success" className="alert-custom">
                                  <i className="iconfont icon-round_check_fill"></i>  {t('paymentSuccessful')}
                                 </Alert> 
                                 : orderStatus==="REFUNDED" ?
                                 <Alert variant="danger" className="alert-custom">
                                    <i className="iconfont icon-warning_fill"></i> {t('orderRefundedDes')} {t('please')} <Link to="/contact">{t('ContactUs')}</Link>.
                                  </Alert>
                                 : orderStatus==="FAILED" ?
                                  <Alert variant="danger" className="alert-custom">
                                      <i className="iconfont icon-warning_fill"></i> {t('orderFailedDes')} {t('please')} <Link to="/contact">{t('ContactUs')}</Link>.
                                  </Alert>
                                 : orderStatus==="ON_HOLD" ?
                                 <Alert variant="warning" className="alert-custom">
                                     <i className="iconfont icon-warning_fill"></i> {t('orderInProgress')}
                                 </Alert>
                                 : orderStatus==="PROCESSING" ?
                                 <Alert variant="warning" className="alert-custom">
                                     <i className="iconfont icon-warning_fill"></i> {t('orderInProgress')}
                                 </Alert>
                                 : orderStatus==="PENDING" ?
                                 <Alert variant="danger" className="alert-custom">
                                     <i className="iconfont icon-warning_fill"></i> {t('orderPending')}
                                 </Alert>
                                 :""                     
      }
      <Container fluid className="checkout-container">
            <div className="order-detail">
              <Row>
                <Col>
                 <h5>{t('orderID')}</h5>
                  <p>{orderNumber}</p>
                </Col>
                <Col>
                  <h5>{t('date')}</h5>
                  <p>{moment(datePaid).format('YYYY-MM-DD')}</p>
                </Col>
                <Col>
                  <h5>{t('paymentMethod')}</h5>
                  <p>{paymentMethodTitle}</p>
                </Col>
                <Col>
                  <h5>{t('shippingAddress')}</h5>
                  <div>
                    {
                      billingAddress ?
                      <div>
                        <p>{billingAddress.firstName} {billingAddress.lastName}</p>
                        <p>{billingAddress.company}</p>
                        <p>{billingAddress.address1}</p>
                        <p>{billingAddress.address2}</p>
                        <p>{billingAddress.city}</p>
                        <p>{billingAddress.state}</p>
                        <p>{billingAddress.postcode}</p>
                        <p>{billingAddress.country}</p>
                        <p>{billingAddress.phone}</p>
                        <p>{billingAddress.email} </p>
                      </div>
                      :""
                    }
                  </div>
                </Col>
              </Row>
              
            </div>
            <Row>
            <h2>{t('itemsOrdered')}</h2>
            <Table responsive="md" className="table-custom">
                <thead>
                  <tr>
                    <th>{t('products')}</th>
                    <th className="text-center">{t('price')}</th>
                    <th className="text-center">{t('quantity')}</th>
                    <th className="text-center">{t('total')}</th>
                  </tr>
                </thead>
                <tbody>
                { 
                  cartProduct.map((item, index) => {
                    return <tr key={`item_${index}`}>
                    <td>
                      <div className="product-info">
                        <div className="product-info-img">
                          <Image src={item.node.product.image ? item.node.product.image.link : img}/>
                        </div>
                        <div>
                          <h5>{item.node.product.name}</h5>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{item.node.product.price || 0}</td>
                    <td className="text-center">{item.node.quantity}</td>
                    <td className="text-center">
                    {item.node.total || 0}
                    </td>
                  
                  </tr>
                  })
                }
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col>
                
                <div>
                  <div className="">
                    <div className="cart-totals checkout-cart-totals">
                      <h3>{t('cartTotals')}</h3>
                      <div className="totals-warp">
                        <div className="flex">
                          <div>{t('subTotal')}</div>
                          <h5>{subTotal}</h5>
                        </div>
                        <div className="flex">
                          <div>{t('tax')}</div>
                          <h5>{totalTax}</h5>
                        </div>
                        <div className="flex">
                          <div><b>{t('total')}</b></div>
                          <h1>{totalPrice}</h1>
                        </div>
                      </div>
                      
                     
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </PageContainer>
  </Layout>
  )
}

export default OrderDetail
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
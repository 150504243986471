import { gql } from "@apollo/client";
const GET_ORDER_DETAIL = gql`
  query GET_ORDER_DETAIL( $id: ID ) {
    order(id: $id) {
      id
      currency
      couponLines {
        edges {
          node {
            discount
            coupon {
              code
              dateExpiry
            }
          }
        }
      }
      customerNote
      dateCompleted
      datePaid
      discountTotal
      lineItems {
        edges {
          node {
            product {
              ... on GroupProduct {
                id
                name
                price
              }
              ... on SimpleProduct {
                id
                name
                price
              }
              ... on ExternalProduct {
                id
                name
                price
              }
              ... on VariableProduct {
                id
                name
                price
              }
              status
              link
              shortDescription
              name
              id
              image {
                link
              }
            }
            quantity
            total
            subtotal
          }
        }
      }
      shipping {
        state
        postcode
        phone
        lastName
        firstName
        email
        country
        company
        city
        address2
        address1
      }
      billing {
        address1
        address2
        city
        company
        country
        email
        lastName
        firstName
        phone
        postcode
        state
      }
      totalTax
      total
      status
      subtotal
      paymentMethod
      paymentMethodTitle
      orderNumber
      orderKey
      modified
      refunds {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export default GET_ORDER_DETAIL;

